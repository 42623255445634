// src/services/organisationService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getOrganisationById = async (organisationId) => {
  const getOrganisationByIdCallable = httpsCallable(functions, 'getOrganisationById');
  const response = await getOrganisationByIdCallable({ organisationId });
  return response.data.organisation;
};


export const updateOrganisation = async (organisationId, organisationData) => {
  const updateOrganisationCallable = httpsCallable(functions, 'updateOrganisation');
  const response = await updateOrganisationCallable({ organisationId, ...organisationData });
  return response.data;
};



// NEW ONES

// NEW ONES

export const getOrganisations = async (scope) => {
  try {
    const getOrganisations = httpsCallable(functions, 'getOrganisations');
    const response = await getOrganisations({ scope }); // Pass the scope parameter
    return response.data;
  } catch (error) {
    console.error('Error fetching organisations:', error);
    throw new Error('Failed to fetch organisations');
  }
};