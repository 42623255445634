import React, { useState } from 'react';
import { Modal, Table, Button, FormControl, Alert } from 'react-bootstrap';

const SkuSelectionModal = ({ show, handleClose, skus, handleSkuSelect, error }) => {
  const [quantities, setQuantities] = useState({});
  const [updateMessage, setUpdateMessage] = useState(null);

  // Function to handle quantity change for a specific SKU
  const handleQuantityChange = (skuId, value) => {
    setQuantities({ ...quantities, [skuId]: value });
  };

  // Function to handle selecting a SKU
  const onSelectSku = async (item, quantity) => {
    try {
      await handleSkuSelect(item, quantity);
      setUpdateMessage({ type: 'success', text: 'Update successful!' });
    } catch (err) {
      setUpdateMessage({ type: 'danger', text: 'Update failed. Please try again.' });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Select a SKU</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {updateMessage && <Alert variant={updateMessage.type}>{updateMessage.text}</Alert>}
        <div style={{ overflowX: 'auto' }}> {/* Make the table scrollable horizontally */}
          <Table striped bordered hover responsive style={{ fontSize: '10pt', width: '100%' }}>
            <thead>
              <tr>
                <th>SKU ID</th>
                <th>Name</th>
                <th>Cut</th>
                <th>Pack Format</th>
                <th>Category</th>
                <th>Unit Size Name</th>
                <th>Product Size Measure</th>
                <th>Pack Type</th>
                <th>Process</th>
                <th>Price</th>
                <th>UOM</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {skus.map((item) => (
                <tr key={item.skuId}>
                  <td>{item.skuId}</td>
                  <td>{item.skuName}</td>
                  <td>{item.cut}</td>
                  <td>{item.packFormat}</td>
                  <td>{item.category}</td>
                  <td>{item.unitSizeName}</td>
                  <td>{item.productSizeMeasure}</td>
                  <td>{item.packType}</td>
                  <td>{item.process}</td>
                  <td>{item.price}</td>
                  <td>{item.uom}</td>
                  <td>
                    <FormControl
                      type="number"
                      placeholder="Qty"
                      value={quantities[item.skuId] || ''}
                      onChange={(e) => handleQuantityChange(item.skuId, e.target.value)}
                      min="0"
                    />
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => onSelectSku(item, quantities[item.skuId])}
                      disabled={!quantities[item.skuId] || parseFloat(quantities[item.skuId]) <= 0}
                    >
                      Select
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SkuSelectionModal;
