import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner, Table } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import '../../styles.css';

const OrganisationDetails = () => {
  const [organisation, setOrganisation] = useState(null);
  const [editableOrganisation, setEditableOrganisation] = useState({});
  const [backupOrganisation, setBackupOrganisation] = useState({});
  const [contactsData, setContactsData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganisation = async () => {
      const query = new URLSearchParams(location.search);
      const organisationId = query.get('organisationId');

      if (!organisationId) {
        console.error('No organisationId found in URL');
        return;
      }

      try {
        const getOrganisationById = httpsCallable(functions, 'getOrganisationById');
        const response = await getOrganisationById({ organisationId });
        const organisationData = response.data.organisation;

        // Ensure all four contacts are present
        const defaultContacts = [
          { designation: 'primary', userId: '' },
          { designation: 'secondary', userId: '' },
          { designation: 'billing', userId: '' },
          { designation: 'other', userId: '' },
        ];

        // Merge existing contacts with the default ones
        const contacts = defaultContacts.map((defaultContact) => {
          const existingContact = organisationData.contacts?.find(
            (contact) => contact.designation === defaultContact.designation
          );
          return existingContact || defaultContact;
        });

        setOrganisation({ ...organisationData, contacts });
        setEditableOrganisation({ ...organisationData, contacts });

        // Fetch orders by organisationId
        const getOrdersByOrganisation = httpsCallable(functions, 'getOrdersByOrganisation');
        const ordersResponse = await getOrdersByOrganisation({ organisationId });
        setOrders(ordersResponse.data.orders || []);

      } catch (error) {
        console.error('Error fetching organisation or orders:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const getUsers = httpsCallable(functions, 'getUsers');
        const response = await getUsers({ roles: ['organisation', 'organisationAdmin'] });
        const users = response.data.users;
        const contactOptions = users.map(user => ({
          value: user.userId,
          label: user.email,
          name: `${user.firstName} ${user.lastName}`,
          jobTitle: user.jobTitle,
          phoneNumber: user.phoneNumber
        }));
        setContactsData(contactOptions);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchOrganisation();
    fetchUsers();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    if (keys.length === 1) {
      setEditableOrganisation({ ...editableOrganisation, [name]: value });
    } else {
      setEditableOrganisation(prevState => {
        const updatedState = { ...prevState };
        let nestedObj = updatedState;

        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            nestedObj[key] = value;
          } else {
            nestedObj = nestedObj[key];
          }
        });

        return updatedState;
      });
    }
  };

  const handleContactChange = (index, userId) => {
    const newContacts = [...editableOrganisation.contacts];
    newContacts[index].userId = userId || '';
    setEditableOrganisation({ ...editableOrganisation, contacts: newContacts });
  };

  const handleEdit = () => {
    setBackupOrganisation(editableOrganisation);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableOrganisation(backupOrganisation);
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setSaveError(false);
    try {
      const updateOrganisation = httpsCallable(functions, 'updateOrganisation');
      await updateOrganisation(editableOrganisation);
      setIsSaved(true);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving organisation:', error);
      setSaveError(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate('/organisations');
  };

  const renderFields = (fields) => (
    <Row>
      {fields.map(({ label, key, type, options, readOnly }) => (
        <Col md={6} key={key} className="mb-3">
          <Form.Group>
            <Form.Label>{label}</Form.Label>
            {type === 'select' ? (
              <Select
                name={key}
                options={options}
                onChange={(selectedOption) =>
                  handleInputChange({
                    target: { name: key, value: selectedOption ? selectedOption.value : '' },
                  })
                }
                value={options.find((option) => option.value === editableOrganisation[key])}
                isDisabled={readOnly || !isEditing || isSaving}
              />
            ) : (
              <Form.Control
                type={type}
                name={key}
                value={key.split('.').reduce((o, i) => (o ? o[i] : ''), editableOrganisation) ?? ''}
                readOnly={readOnly || !isEditing || isSaving}
                onChange={handleInputChange}
              />
            )}
          </Form.Group>
        </Col>
      ))}
    </Row>
  );

  const contactOptions = contactsData;

  const getContactDetails = (userId) => {
    return contactsData.find((contact) => contact.value === userId) || {};
  };

  const renderContactFields = (contact, index) => {
    const contactDetails = getContactDetails(contact.userId);
    return (
      <div key={contact.designation}>
        <h5>{contact.designation.charAt(0).toUpperCase() + contact.designation.slice(1)} Contact</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              {isEditing ? (
                <Select
                  options={contactOptions}
                  onChange={(selectedOption) =>
                    handleContactChange(index, selectedOption.value)
                  }
                  value={contactOptions.find((option) => option.value === contact.userId)}
                  isDisabled={!isEditing || isSaving}
                />
              ) : (
                <Form.Control
                  type="text"
                  value={contactDetails.label || ''}
                  readOnly
                />
              )}
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={contactDetails.name || ''}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={contactDetails.phoneNumber || ''}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group>
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type="text"
                value={contactDetails.jobTitle || ''}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!organisation) {
    return <p>Loading...</p>;
  }

  const statusOptions = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
  ];

  const basicInformation = [
    { label: 'Organisation Name', key: 'organisationName', type: 'text' },
    { label: 'Trading Name', key: 'tradingName', type: 'text' },
    { label: 'ABN', key: 'ABN', type: 'text' },
    { label: 'Organisation Status', key: 'organisationStatus', type: 'select', options: statusOptions },
    { label: 'Organisation Homepage', key: 'organisationHomepage', type: 'text' },
    { label: 'Organisation Email', key: 'organisationEmail', type: 'email' },
    { label: 'Organisation Phone', key: 'organisationPhone', type: 'text' },
  ];

  const addressInformation = (prefix) => [
    { label: 'Address Line 1', key: `${prefix}addressLine1`, type: 'text' },
    { label: 'Address Line 2', key: `${prefix}addressLine2`, type: 'text' },
    { label: 'City', key: `${prefix}city`, type: 'text' },
    { label: 'Region', key: `${prefix}region`, type: 'text' },
    { label: 'Postcode', key: `${prefix}postcode`, type: 'text' },
    { label: 'Country', key: `${prefix}country`, type: 'text' },
  ];

  return (
    <Container className="content">
      <h1>Organisation Details</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
              <FaEdit className="me-2 mb-1" />
              Edit
            </Button>
          )}
        </div>
      </div>
      {isSaved && <Alert variant="success">Record has been saved</Alert>}
      {saveError && <Alert variant="danger">Could not save record</Alert>}
      <Card className="mb-3">
        <Card.Header>Basic Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(basicInformation)}</Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Delivery Details</Card.Header>
        <Card.Body>
          <Form>{renderFields(addressInformation('deliveryAddress.'))}</Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Billing Details</Card.Header>
        <Card.Body>
          <Form>{renderFields(addressInformation('billingAddress.'))}</Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Contact Details</Card.Header>
        <Card.Body>
          <Form>
            {editableOrganisation.contacts.map(renderContactFields)}
          </Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Order History</Card.Header>
        <Card.Body>
          {orders.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Created At</th>
                  <th>Order Status</th>
                  <th>Order Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.orderId}>
                    <td>{order.orderId}</td>
                    <td>{new Date(order.createdAt._seconds * 1000).toLocaleString()}</td>
                    <td>{order.orderStatus}</td>
                    <td>${order.orderTotal.toFixed(2)}</td>
                    <td>
                      <Link to={`/order-details?orderId=${order.orderId}`}>
                        View Details
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No orders found for this organisation.</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OrganisationDetails;
