import React, { useEffect, useState } from 'react';
import { Container, Table, FormControl, Spinner, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getOrganisations } from '../../services/organisationService'; // Update the path as needed
import '../../styles.css';

const Organisations = () => {
  const [organisationsData, setOrganisationsData] = useState([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState(''); // State to store selected type for filtering
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganisationsData = async () => {
      try {
        const result = await getOrganisations('all');  // Call the service with a scope (e.g., 'all' in this case)
        if (result.organisations && Array.isArray(result.organisations)) {
          setOrganisationsData(result.organisations);  // Ensure the data is an array
          setFilteredOrganisations(result.organisations);
        } else {
          setError('Unexpected data format');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching organisations data:', error);
        setError('Error fetching organisations data');
        setLoading(false);
      }
    };

    fetchOrganisationsData();
  }, []);

  useEffect(() => {
    let results = organisationsData;

    if (searchTerm) {
      results = results.filter((organisation) =>
        Object.values(organisation).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (selectedType) {
      results = results.filter((organisation) => organisation.types && organisation.types.includes(selectedType));
    }

    setFilteredOrganisations(results);
  }, [searchTerm, organisationsData, selectedType]);

  const handleRowClick = (organisationId) => {
    navigate(`/organisation-details?organisationId=${organisationId}`);
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Organisations Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Dropdown onSelect={handleTypeSelect}>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {selectedType || 'Filter by Type'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="">All Types</Dropdown.Item>
            <Dropdown.Item eventKey="buyer">Buyer</Dropdown.Item>
            <Dropdown.Item eventKey="seller">Seller</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Table striped bordered hover size="sm" style={{ fontSize: '12px' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Trading Name</th>
            <th>ABN</th>
            <th>Created</th>
            <th>Last Updated</th>
            <th>Types</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrganisations.map((organisation) => (
            <tr key={organisation.organisationId} onClick={() => handleRowClick(organisation.organisationId)} style={{ cursor: 'pointer' }}>
              <td>{organisation.organisationId}</td>
              <td>{organisation.organisationName}</td>
              <td>{organisation.tradingName || 'N/A'}</td>
              <td>{organisation.ABN}</td>
              <td>{new Date(organisation.createdAt).toLocaleDateString() || 'N/A'}</td>
              <td>{new Date(organisation.updatedAt).toLocaleDateString() || 'N/A'}</td>
              <td>{organisation.types?.join(', ') || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Organisations;
