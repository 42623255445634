import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SkuSelectionModal from './SkuSelectionModal'; // Import the modal component
import { updatePackedItems } from '../services/orderService'; // Ensure correct path
import './SkuItem.css'; // Import custom CSS for styling

const SkuItem = ({ producerOrder, sku, skus, handleSkuClick }) => {
  const [orderedItemTotal, setOrderedItemTotal] = useState(0);
  const [packedItemTotal, setPackedItemTotal] = useState('-');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  // Get the ordered SKU details
  const orderedSku = skus.find((item) => item.skuId === sku.skuId);

  // Retrieve the packedSku details from producerOrder
  const packedSkuDetails = producerOrder.packedSkus?.find((packedItem) => packedItem.skuId === sku.skuId);
  const packedSkuId = packedSkuDetails?.packedSkuId || null;
  const packedQuantity = packedSkuDetails ? parseFloat(packedSkuDetails.packedQuantity) : null;

  // Fetch the packedSku details from the list of skus
  const packedSku = skus.find((item) => item.skuId === packedSkuId);

  useEffect(() => {
    if (orderedSku) {
      setOrderedItemTotal(orderedSku.price * sku.quantity);
    }
  }, [orderedSku, sku.quantity]);

  useEffect(() => {
    if (packedSku && !isNaN(packedQuantity)) {
      setPackedItemTotal(packedSku.price * packedQuantity);
    } else {
      setPackedItemTotal('-');
    }
  }, [packedSku, packedQuantity]);

  // Function to handle modal open/close
  const handleModalToggle = () => {
    setShowModal(!showModal);
    setError(null); // Clear any existing errors when toggling the modal
  };

  // Function to handle selecting an SKU from the modal table
  const handleSkuSelect = async (selectedSku, selectedQuantity) => {
    if (!selectedQuantity || parseFloat(selectedQuantity) <= 0) {
      setError('Please enter a valid quantity.');
      return;
    }

    // Prepare the payload for API call
    const payload = {
      orderId: producerOrder.orderId,
      producerOrderId: producerOrder.producerOrderId,
      packedSkus: [
        {
          skuId: sku.skuId,
          packedSkuId: selectedSku.skuId,
          packedSkuName: selectedSku.skuName,
          packedSkuCategory: selectedSku.category,
          packedSkuCut: selectedSku.cut,
          packedSkuUnitSizeName: selectedSku.unitSizeName,
          packedSkuUnitSizeDetail: selectedSku.unitSizeDetail,
          packedSkuProductSizeMeasure: selectedSku.productSizeMeasure,
          packedSkuPackType: selectedSku.packType,
          packedSkuProcess: selectedSku.process,
          packedSkuPrice: selectedSku.price,
          packedSkuUom: selectedSku.uom,
          packedSkuGrade: selectedSku.grade,
          packedQuantity: parseFloat(selectedQuantity),
        },
      ],
    };

    try {
      await updatePackedItems(payload);
      // Update the packed detail states directly
      sku.packedSkuId = selectedSku.skuId;
      sku.packedQuantity = selectedQuantity;
      // Refresh page after 2 seconds
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setError('Failed to update packed items. Please try again.');
    }
  };

  // Check if the edit button should be disabled based on producer order status
  const isEditDisabled = producerOrder.producerOrderStatus === 'OUT_FOR_DELIVERY' || producerOrder.producerOrderStatus === 'DELIVERED';

  return (
    <>
      <Row className="g-3 d-flex align-items-stretch mb-2">
        {/* Ordered Section */}
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill rounded" style={{ borderRight: '1px solid #dee2e6', marginBottom: '10px' }}>
            <Card.Body>
              <p>
                <strong>Ordered:</strong>
              </p>
              <p>
                <table style={{ fontSize: '10pt', width: '100%' }}>
                  <tbody>
                    <tr>
                      <td><strong>SKU ID:</strong></td>
                      <td>{orderedSku?.skuId || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>SKU Name:</strong></td>
                      <td>{orderedSku?.skuName || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Cut:</strong></td>
                      <td>{orderedSku?.cut || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Pack Format:</strong></td>
                      <td>{orderedSku?.packFormat || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Category:</strong></td>
                      <td>{orderedSku?.category || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Unit Size Name:</strong></td>
                      <td>{orderedSku?.unitSizeName || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Unit Size Detail:</strong></td>
                      <td>{orderedSku?.unitSizeDetail || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Product Size Measure:</strong></td>
                      <td>{orderedSku?.productSizeMeasure || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Pack Type:</strong></td>
                      <td>{orderedSku?.packType || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Process:</strong></td>
                      <td>{orderedSku?.process || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Price:</strong></td>
                      <td>{orderedSku?.price || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>UOM:</strong></td>
                      <td>{orderedSku?.uom || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Grade:</strong></td>
                      <td>{orderedSku?.grade || 'N/A'}</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <p><strong>Quantity:</strong> {sku.quantity}</p>
              <p><strong>Item Total:</strong> {`$${orderedItemTotal.toFixed(2)}`}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Packed Section */}
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill rounded" style={{ borderLeft: '1px solid #dee2e6', marginBottom: '10px' }}>
            <Card.Body>
              <p>
                <strong>Packed:</strong>
              </p>
              <p>
                <table style={{ fontSize: '10pt', width: '100%' }}>
                  <tbody>
                    <tr>
                      <td><strong>SKU ID:</strong></td>
                      <td>{packedSku?.skuId || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>SKU Name:</strong></td>
                      <td>{packedSku?.skuName || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Cut:</strong></td>
                      <td>{packedSku?.cut || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Pack Format:</strong></td>
                      <td>{packedSku?.packFormat || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Category:</strong></td>
                      <td>{packedSku?.category || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Unit Size Name:</strong></td>
                      <td>{packedSku?.unitSizeName || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Unit Size Detail:</strong></td>
                      <td>{packedSku?.unitSizeDetail || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Product Size Measure:</strong></td>
                      <td>{packedSku?.productSizeMeasure || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Pack Type:</strong></td>
                      <td>{packedSku?.packType || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Process:</strong></td>
                      <td>{packedSku?.process || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Price:</strong></td>
                      <td>{packedSku?.price || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>UOM:</strong></td>
                      <td>{packedSku?.uom || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td><strong>Grade:</strong></td>
                      <td>{packedSku?.grade || 'N/A'}</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <p><strong>Quantity:</strong> {packedQuantity || '-'}</p>
              <p><strong>Item Total:</strong> {packedItemTotal === '-' ? '-' : `$${packedItemTotal.toFixed(2)}`}</p>

              {isEditDisabled ? (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip>This order is already out for delivery or has been delivered.</Tooltip>}
  >
    <span className="d-inline-block">
      <Button variant="primary" onClick={handleModalToggle} disabled style={{ pointerEvents: 'none' }}>
        Edit
      </Button>
    </span>
  </OverlayTrigger>
) : (
  <Button variant="primary" onClick={handleModalToggle}>
    Edit
  </Button>
)}

            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Sku Selection Modal */}
      <SkuSelectionModal
        show={showModal}
        handleClose={handleModalToggle}
        skus={skus}
        handleSkuSelect={handleSkuSelect}
        error={error}
      />
    </>
  );
};

export default SkuItem;
